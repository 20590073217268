import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Home from '@/views/Home'
import Ad from '@/views/Ad'
import AdRepresentative from '@/views/AdRepresentative'
import History from '@/views/History'
import Documents from '@/views/Documents'
import CreativesWrapper from '@/views/CreativesWrapper'
import VkAccounts from '@/views/VkAccounts'
import VkAdds from '@/views/VkAdds'
import Support from '@/views/Support'
import SupportDetail from '@/views/SupportDetail'
import Reports from '@/views/Reports'
import Login from '@/views/Login'
import Registration from '@/views/Registration'
import ForgetPassword from '@/views/ForgetPassword'
import ResetPassword from '@/views/ResetPassword'
import VerifyEmail from '@/views/VerifyEmail'
import PageNotFound from '@/views/PageNotFound'
import Profile from '@/views/Profile'
import Terms from '@/views/Terms'
import Payment from '@/views/Payment'
import Cashback from '@/views/Cashback'
import Users from '@/views/Users'
import Clients from '@/views/Clients'
import CookiesPage from "@/views/CookiesPage";
import DogovorOferty from "@/views/DogovorOferty";
import DogovorOfertyFl from "@/views/DogovorOfertyFl";
import PrivacyPolicy from "@/views/PrivacyPolicy";
import Agreement from "@/views/Agreement";
import BusinessAccounts from '@/views/BusinessAccounts'
import ClientsInfo from '@/views/ClientsInfo'
import Representatives from '@/views/Representatives'
import Main from '@/views/Main'
import TechnicalWork from '@/views/TechnicalWork'
import UnsubscribeEmail from '@/views/UnsubscribeEmail'
import ActivePromo from '@/views/ActivePromo'
import FAQ from '@/views/FAQ'
import RegistrationPromotion from '@/views/RegistrationPromotion'
import SpecialTerms from '@/views/SpecialTerms'
import ReferralStatistics from '@/views/ReferralStatistics'
import ProgramDescription from '@/views/ProgramDescription'
import ReferralHistory from '@/views/ReferralHistory'
import TransferAccounts from '@/views/TransferAccounts'


Vue.use(VueRouter)
const locale = process.env.VUE_APP_LOCALE

const routes = [
  {
    path: '/statistics',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/ad',
    name: 'Ad',
    component: Ad,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/ad-representative',
    name: 'AdRepresentative',
    component: AdRepresentative,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/ad',
    name: 'Payment',
    component: Payment,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/history',
    name: 'History',
    component: History,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/creatives',
    name: 'Creatives',
    component: CreativesWrapper,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (locale === 'USA') {
        next('/') 
      } else {
        next() 
      }
    }
  },
  {
    path: '/documents',
    name: 'Documents',
    component: Documents,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/vk-accounts',
    name: 'VkAccounts',
    component: VkAccounts,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/vk-adds',
    name: 'VkAdds',
    component: VkAdds,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/support',
    name: 'Support',
    component: Support,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/support/:id',
    name: 'SupportDetail',
    component: SupportDetail,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/reports',
    name: 'Reports',
    component: Reports,
    meta: {
      requiresAuth: true,
      onlyAdmin: true,  
      layout: 'manager-layout',
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms,
    meta: {
      layout: 'auth-layout',
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      layout: 'auth-layout',
    }
  },
  {
    path: '/registration/:optionalParam?',
    name: 'Registration',
    component: Registration,
    meta: {
      layout: 'auth-layout',
    }
  },
  {
    path: '/registration-promotion',
    name: 'RegistrationPromotion',
    component: RegistrationPromotion,
    meta: {
      layout: 'blanket-layout',
    }
  },
  {
    path: '/forget-password',
    name: 'ForgetPassword',
    component: ForgetPassword,
    meta: {
      layout: 'auth-layout',
    }
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      layout: 'auth-layout',
    }
  },
  {
    path: '/verify-email',
    name: 'VerifyEmail',
    component: VerifyEmail,
    meta: {
      layout: 'auth-layout',
    }
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
    meta: {
      requiresAuth: true,
      layout: 'manager-layout',
    }
  },
  {
    path: '/clients-accounts',
    name: 'Clients',
    component: Clients,
    meta: {
      requiresAuth: true,
      layout: 'manager-layout',
    },
    beforeEnter: (to, from, next) => {
      const activeBA =  localStorage.getItem('activeBA');
      const activeClient = localStorage.getItem('active_client');
      const activeClientEmail = localStorage.getItem('active_client_email');


      if (activeClient || activeClientEmail || activeBA) {
          localStorage.removeItem('active_client');
          localStorage.removeItem('active_client_email');
          localStorage.removeItem('activeBA');
      }

      store.commit('Auth/setData', { label: 'businessAccounts', data: null });
      store.commit('Auth/setData', { label: 'activeAccount', data: null });
      next();
    },
  },
  {
    path: '/active-promo',
    name: 'ActivePromo',
    component: ActivePromo,
    meta: {
      requiresAuth: true,
      layout: 'manager-layout',
    }
  },
  {
    path: '/faq-admin',
    name: 'FAQ',
    component: FAQ,
    meta: {
      requiresAuth: true,
      layout: 'manager-layout',
    }
  },
  {
    path: '/clients-info',
    name: 'ClientsInfo',
    component: ClientsInfo,
    meta: {
      requiresAuth: true,
      layout: 'manager-layout',
    }
  },
  {
    path: '/representatives',
    name: 'Representatives',
    component: Representatives,
  },
  {
    path: '/business-accounts',
    name: 'BusinessAccounts',
    component: BusinessAccounts,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/conditions',
    name: 'Cashback',
    component: Cashback,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '*',
    name: 'PageNotFound',
    component: PageNotFound,
    meta: {
      layout: 'error-layout'
    }
  },
  {
    path: '/',
    redirect: '/login',
    name: 'MainPage',
    component: Main,
    meta: {
      layout: 'main-layout'
    }
  },
  {
    path: '/cookies',
    name: 'CookiesPage',
    component: CookiesPage,
    meta: {
      layout: 'simple-layout',
    }
  },
  {
    path: '/technical-works',
    name: 'TechnicalWork',
    component: TechnicalWork,
    meta: {
      layout: 'simple-layout',
    }
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
     beforeEnter: (to, from, next) => {
      if (locale === 'RU') {
        window.open('https://profitads.ru/dogovor-oferty/', '_blank')
        next(false)
      } else {
        next()
      }
    },
    meta: {
      layout: 'simple-layout',
    }
  },
  {
    path: '/dogovor-oferty',
    name: 'DogovorOferty',
    component: DogovorOferty,
    beforeEnter: (to, from, next) => {
      if (locale === 'RU') {
        window.open('https://profitads.ru/dogovor-oferty/', '_blank')
        next(false)
      } else {
        next()
      }
    },
    meta: {
      layout: 'simple-layout',
    }
  },
  {
    path: '/dogovor-oferty-fl',
    name: 'DogovorOfertyFl',
    component: DogovorOfertyFl,
    beforeEnter: (to, from, next) => {
      if (locale === 'RU') {
        window.open('https://profitads.ru/dogovor-oferty-fl/', '_blank')
        next(false)
      } else {
        next()
      }
    },
    meta: {
      layout: 'simple-layout',
    }
  },
  {
    path: '/agreement',
    name: 'Agreement',
    component: Agreement,
    beforeEnter: (to, from, next) => {
      if (locale === 'RU') {
        window.open('https://profitads.ru/agreement/', '_blank')
        next(false)
      } else {
        next()
      }
    },
    meta: {
      layout: 'simple-layout',
    }
  },
  {
    path: '/special-terms',
    name: 'SpecialTerms',
    component: SpecialTerms,
    meta: {
      layout: 'simple-layout',
    },
    beforeEnter: (to, from, next) => {
      if (locale === 'USA') {
        next();
      } else {
        next('/');
      }
    }
  },
  {
    path: '/faq',
    name: 'HelpDesk',
    component: () => import('../views/Helpdesc'),
    meta: {
      requiresAuth: true
    },

    beforeEnter: (to, from, next) => {
      if (locale === 'USA' && to.path !== '/faq/how-add') {
        next('/faq/how-add');
      } else {
        next();
      }
    },
    
    children: [
      {
        path: 'how-add',
        component: () => import('../views/Helpdesc/HowAdd.vue')
      },
      { 
        path: 'how-replenish-total',
        component: () => import('../views/Helpdesc/howToReplenishTotal.vue')
      },
      {
        path: 'how-replenish-balance',
        component: () => import('../views/Helpdesc/howToReplenishBalance.vue')
      },
      {
        path: 'how-withdraw',
        component: () => import('../views/Helpdesc/howToWithDraw.vue')
      },
      {
        path: 'how-commission-terms',
        component: () => import('../views/Helpdesc/howToKnowTermsOfCommissions.vue')
      },
      {
        path: 'how-cashback-terms',
        component: () => import('../views/Helpdesc/howToKnowTermsOfCashBack.vue')
      },
      {
        path: 'how-invoice',
        component: () => import('../views/Helpdesc/howToGetInvoice.vue')
      }
    ]
  },
  {
    path: '/unsubscribe-email',
    name: 'UnsubscribeEmail',
    component: UnsubscribeEmail,
    meta: {
      layout: 'simple-layout',
    }
  },
  {
    path: '/referral-statistics',
    name: 'ReferralStatistics',
    component: ReferralStatistics,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/program-description',
    name: 'ProgramDescription',
    component: ProgramDescription,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/referral-history',
    name: 'ReferralHistory',
    component: ReferralHistory,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/transfer-accounts',
    name: 'TransferAccounts',
    component: TransferAccounts,
    meta: {
      requiresAuth: true,
      layout: 'manager-layout'
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.state.Auth.token) {
      await store.dispatch('Auth/getUnreadNotifications');
      if (localStorage.getItem('verified') === 'true') {
        if (to.matched.some(record => record.meta.onlyAdmin) && store.state.Auth.role !== 'admin') {
          next('/clients-accounts');
        } else if (store.state.Auth.role === 'partner') {
          const partnerPaths = ['/ad-representative', '/history', '/creatives', '/documents', '/conditions', '/support', '/vk-adds', '/vk-accounts'];
          const isPartnerPath = partnerPaths.includes(to.path) || /^\/support\/[a-zA-Z0-9-]+$/.test(to.path);
          if (isPartnerPath) {
            next();
          } else {
            next('/ad-representative');
          }
        } else {
          next();
        }
      } else {
        next('/verify-email');
      }
    } else {
      next('/login');
    }
  } else {
    next();
  }
});


export default router
