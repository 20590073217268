<template>
    <div class="referral-statistics">
        <div class="referral-statistics__search">
            <div class="referral_link">
                <span class="referral_link__title">Реферальная ссылка</span>
                <div class="referral_link__wrapper">
                    <span class="referral_link__value">{{ link }}</span>
                    <img class="referral_link__copy" src="@/assets/icons/icon-copy-clip.svg" alt="copy link"
                        @click="doCopy">
                </div>
                <div class="referral_link__wrapper">
                    <span class="referral_link__value">{{ agentLink }}</span>
                    <img class="referral_link__copy" src="@/assets/icons/icon-copy-clip.svg" alt="copy link"
                        @click="doCopyAgentLink">
                </div>
            </div>
            <!-- <Search :placeholder="$t('search_props.placeholder')" @findValue="changeSearchText" /> -->
        </div>
        <!-- <div v-if="usersList" class="referral-statistics__table" :class="{ 'locale-usa': locale === 'USA' }">
            <el-table 
            :data="usersList" 
            :show-header="false" 
            style="width: 100%" 
            :row-class-name="tableRowClassName">
                <el-table-column min-width="200">
                    <template slot-scope="scope">
                        <div class="higlited-title">
                            {{ (scope.row.email) }}
                        </div>
                    </template>
</el-table-column>
<el-table-column width="300">
    <template slot-scope="scope">
                        <div class="higlited-title button-style" @click="getDetailStatistics(scope.row.id)">
                            Получить детальную статистику 
                        </div>
                    </template>
</el-table-column>
<div class="el-table__empty-block" slot="append" v-if="!usersList || usersList == '0'">
    <p class="el-table__empty-text ">{{ locale === 'RU' ? 'Нет данных' : 'No data' }}</p>
</div>
</el-table>
<div v-if="pagination && pagination.total > 15 && pagination.lastPage !== currentPage" class="ad-page__accounts-more"
    :class="{ 'locale-usa': locale === 'USA' }" @click="showMore">
    <img :src="getPic(locale)" alt="icon">
    {{ $t('show_more') }}
</div>
</div> -->
        <div class="referral-statistics">
            <div class="referral-statistics-header">
                <div class="referral-statistics__tabs">
                    <div :class="['referral-statistics__tab', { active: activeTab === 'business' }]"
                        @click="activeTab = 'business'">
                        Бизнес-аккаунты
                    </div>
                    <div :class="['referral-statistics__tab', { active: activeTab === 'referrals' }]"
                        @click="activeTab = 'referrals'">
                        Реферальные ссылки
                    </div>
                </div>
                <button :class="['referral-statistics-button__create', { active: activeTab === 'referrals' }]" @click="openPrepaidDialog">
                    Создать реферальную ссылку
                </button>
            </div>

            <div v-if="activeTab === 'business'">
                <div v-if="usersList && usersList.length" class="referral-statistics__table-title">
                    Бизнес-аккаунты
                </div>
                <div v-if="usersList && usersList.length" class="referral-statistics__table">
                    <div v-for="item in usersList" :key="item.id" class="referral-statistics__row">
                        <div>{{ item.email }}</div>
                        <div class="higlited-title button-style" @click="getDetailStatistics(item.id)">
                            Получить детальную статистику
                        </div>
                    </div>
                </div>
                <div v-else class="app-add-business-account">
                    <div class="app-no-data__title">{{ $t('referral_data.no_referral') }}</div>
                    <div class="app-no-data__text">{{ $t('referral_data.invite_referrals') }}</div>
                    <ButtonElement :text="$t('button_props.send_invitations')" class="app-no-data__button"
                        @click-button="doCopy" />
                </div>
            </div>

            <div v-else-if="activeTab === 'referrals'">
                <div class="referral-statistics">
                    <div v-if="agentsLinksList" class="referral_list" :key="agentsLinksList.length">
                        <div class="referral_list-item" v-for="(item) in agentsLinksList" :key="item.id">
                            <div @click="openReferralDialog(item.referral_code)" class="referral_list-name">{{ item.title }}</div>
                            <div>
                                <div class="referral_link__wrapper">
                                    <span class="referral_link__value">{{ item.link }}</span>
                                    <img class="referral_link__copy" src="@/assets/icons/icon-copy-clip-grey.svg" alt="copy link"
                                        @click="copyReferralLink(item.link)">
                                </div>
                                <div class="referral_link__wrapper">
                                    <span class="referral_link__value">https://profitads.ru/?agent_id={{item.referral_code}}</span>
                                    <img class="referral_link__copy" src="@/assets/icons/icon-copy-clip-grey.svg" alt="copy link"
                                        @click="copyReferralLink(`https://profitads.ru/?agent_id=${item.referral_code}`)">
                                </div>
                                <!-- <div v-for="(url, urlIndex) in item.urls" :key="urlIndex" class="referral_link__block">
                                    <span class="referral_link__value">{{ url }}</span>
                                    <img class="referral_link__copy" src="@/assets/icons/icon-copy-clip-grey.svg" alt="copy link"
                                        @click="doCopyAgentLink">
                                </div> -->
                            </div>
                        </div> 
                    </div>  
                        <!-- <div class="referral_link__wrapper">
                            <span class="referral_link__value">{{ link }}</span>
                            <img class="referral_link__copy" src="@/assets/icons/icon-copy-clip.svg" alt="copy link"
                                @click="doCopy">
                        </div> -->
                        <!-- <div class="referral_link__wrapper">
                            <span class="referral_link__value">{{ agentLink }}</span>
                            <img class="referral_link__copy" src="@/assets/icons/icon-copy-clip.svg" alt="copy link"
                                @click="doCopyAgentLink">
                        </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex"

const tableRowClassName = ({
    row
}) => {
    if (row.has_new_comment) {
        return 'higlited'
    }
    return ''
}

// import Search from "@/components/sort/Search";
import ButtonElement from "@/components/elements/ButtonElement";
export default {
    name: 'ReferralStatistics',
    components: { ButtonElement },
    data() {
        return {
            locale: process.env.VUE_APP_LOCALE,
            tableRowClassName,
            currentPage: 1,
            activeTab: 'business',
            links: [
                { name: 'Вконтакте 3%', id: '674EF9911E96C', urls: ['https://profitads.ru/?agent_id=6698B632DBC61', 'https://lk.profitads.ru/registration/6698B632DBC61'] },
                { name: 'My Target 10%', id: '674EF9911E96C', urls: ['https://profitads.ru/?agent_id=6698B632DBC61', 'https://lk.profitads.ru/registration/6698B632DBC61'] },
                { name: 'Facebook 15%', id: '674EF9911E96C', urls: ['https://profitads.ru/?agent_id=6698B632DBC61', 'https://lk.profitads.ru/registration/6698B632DBC61'] },
                { name: 'Вконтакте 3%', id: '674EF9911E96C', urls: ['https://profitads.ru/?agent_id=6698B632DBC61', 'https://lk.profitads.ru/registration/6698B632DBC61'] },
                { name: 'My Target 10%', id: '674EF9911E96C', urls: ['https://profitads.ru/?agent_id=6698B632DBC61', 'https://lk.profitads.ru/registration/6698B632DBC61'] },
                { name: 'Facebook 15%', id: '674EF9911E96C', urls: ['https://profitads.ru/?agent_id=6698B632DBC61', 'https://lk.profitads.ru/registration/6698B632DBC61'] }
            ]
        }
    },
    async beforeMount() {
        if (this.user && this.user.agent && Object.keys(this.user.agent).length) {
            this.getUsers();
            this.getAgentListLinks();
        }
    },
    computed: {
        ...mapState('Referrals', ['agentsLinksList']),
        pagination() {
            return this.$store.state.Support.helpdeskPagination
        },
        user() {
            return this.$store.state.Auth.user
        },
        usersList() {
            return this.$store.state.Referrals.usersList
        },
        agent() {
            return this.$store.state.Auth.user.agent
        },
        link() {
            return this.agent?.link
        },
        agentId() {
            return this.agent?.referral_code;
        },
        agentLink() {
            return `https://profitads.ru/?agent_id=${this.agentId}`
        },
        activeAccount() {
            return this.$store.state.Auth.activeAccount
        },
    },
    watch: {
        user: {
            deep: true,
            handler() {
                if (this.user && this.user.agent && Object.keys(this.user.agent).length) {
                    this.getUsers();
                    this.getAgentListLinks();
                }
            }
        },
    },
    methods: {
        openPrepaidDialog() {
            this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'CreateReferralLInk' })
            this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
        },
        openReferralDialog(referralId) {
            this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'ShowReferralLink' })
            this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
            this.$store.commit('Dialog/setData', { label: 'dialogData', data: { referralId, agent: this.agent } });
        },
        changeSearchText(val) {
            if (!val) {
                const query = { ...this.$route.query, page: 1, search_text: null }
                this.$router.push({ query })
            } else {
                const query = { ...this.$route.query, page: 1, search_text: val }
                this.$router.push({ query })
            }
        },
        showMore() {
            this.currentPage++
            this.getHelpdesk()
        },
        getHelpdesk() {
            this.$store.dispatch('Support/getHelpdesk', {
                status: this.activeTab,
                page: this.currentPage,
            })
        },
        copyReferralLink(link) {
            this.$copyText(link)
                .then(() => {
                    this.$notify({
                        message: this.$t('referral_data.copied'),
                        type: 'success'
                    })
                },
                    function () {
                        this.$notify({
                            message: this.$t('referral_data.error'),
                            type: 'error'
                        });
                    })
        },
        doCopy() {
            this.$copyText(this.link)
                .then(() => {
                    this.$notify({
                        message: this.$t('referral_data.copied'),
                        type: 'success'
                    })
                },
                    function () {
                        this.$notify({
                            message: this.$t('referral_data.error'),
                            type: 'error'
                        });
                    })
        },
        doCopyAgentLink() {
            this.$copyText(this.agentLink)
                .then(() => {
                    this.$notify({
                        message: this.$t('referral_data.copied'),
                        type: 'success'
                    })
                },
                    function () {
                        this.$notify({
                            message: this.$t('referral_data.error'),
                            type: 'error'
                        });
                    })
        },
        getUsers() {
            const clientToken = localStorage.getItem('client_token')
            const data = {
                token: clientToken,
                id: this.user.agent.id
            }
            this.$store.dispatch('Referrals/getUsers', data)
        },
        getAgentListLinks() {
            const clientToken = localStorage.getItem('client_token')
            const data = {
                token: clientToken,
                id: this.user.agent.id
            }
            this.$store.dispatch('Referrals/getAgentListLinks', data)
        },
        getDetailStatistics(id) {
            const clientToken = localStorage.getItem('client_token')
            const data = {
                token: clientToken,
                agent: id
            }

            this.$store.dispatch('Referrals/getDetailStatistics', data)
                .then(() => {
                    this.$notify({
                        message: this.$t('prepaid.notify_messages.2'),
                        type: 'success'
                    });
                })
                .catch((error) => {
                    this.$notify({
                        message: error,
                        type: 'error'
                    });
                })
        }
    }

}
</script>

<style lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.hovered {
    background-color: #F5F7FA;
}

.referral-statistics {
    display: flex;
    flex-direction: column;
    // align-items: center;

    .app-add-business-account {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 50%;
        text-align: center;
        margin: auto;
        margin-top: 10rem;
    }

    &__row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 5rem;
        font-family: 'Roboto';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 1.2rem;

        &:not(:last-child) {
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        }
    }

    &-header{
        display: flex;
        width: 100%;
        justify-content: space-between;
        column-gap: 20px;
        margin-bottom: 20px;
    }

    &__search {
        display: flex;
        width: 100%;
        justify-content: space-between;
        column-gap: 7rem;
        min-height: 5rem;
        margin-bottom: 2rem;
    }

    &__table-title {
        margin-bottom: 1.4rem;
        color: #9BA2AB;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    &__tabs {
        width: 100%;
        display: flex;
        column-gap: 8px;
    }

    &__tab {
        background: #E6EAF2;
        padding: 16px 40px;
        color: #9BA2AB;
        font-size: 16px;
        font-weight: 700;
        line-height: 18.75px;
        border-radius: 7.64px;
        text-wrap: nowrap;
        cursor: pointer;

        &.active {
            background: #D6E0F2;
            color: #333333;
            box-shadow: 0px 5px 10px 0px #9E9E9E1A;
        }
    }

    &-button__create {
        visibility: hidden;
        /* opacity: 0; */
        border: 1.5px solid #2F80ED;
        padding: 10px 0; //check
        width: 100%;
        max-width: 330px;
        text-align: center;
        background: transparent;
        color: #395FE4;
        border-radius: 4px;
        font-size: 16px;
        font-weight: 700;
        line-height: 18.75px;


        &.active {
            visibility: visible;
            /* opacity: 1; */
        }
    }
}

.referral_list {
    display: flex;
    flex-direction: column;

    &-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
        height: 84px;
        border-bottom: 1px solid #00000026;
    }

    &-name {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
    }
}

.referral_link {
    display: flex;
    flex-direction: column;
    padding: 0.8rem 1.6rem;
    border-radius: 4px;
    border: 1.5px solid var(--primary);
    background: var(--primary);
    color: #fff;
    max-width: 50rem;
    width: 100%;
    row-gap: 0.5rem;

    &__title {
        font-family: 'Roboto';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    &__value {
        font-family: 'Roboto';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-decoration-line: underline;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__wrapper {
        display: flex;

    }

    &__copy {
        margin-left: 0.6rem;

        &:hover {
            cursor: pointer;
        }
    }

    &__block {
        display: flex;
        align-items: center;
        margin-bottom: 4px;
    }
}

.button-style {
    color: #fff;
    background-color: var(--primary);
    font-family: 'Roboto';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 12px 19px 14px 17px;
    border-radius: 4px;
    text-align: center;

    &:hover {
        cursor: pointer;
    }
}

.el-table {
    .el-table__cell {
        padding-left: 1rem;
        padding-right: 1.2rem;
    }
}

@include below(769px) {
    .referral-statistics {
        &__row {
            flex-direction: column;
            row-gap: 1.2rem;

            .button-style {
                width: 100%;

            }
        }
    }
}
</style>