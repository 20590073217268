<template>
    <!-- <div v-if="isShowPage" class="program-description"> -->
    <div  class="program-description">
        <div class="program-description__benefits">
            <h3>Преимущества и условия партнерской программы</h3>
            <p>
                Наша партнерская программа позволит вам увеличить вашу прибыль, привлекая новых клиентов с помощью вашей
                уникальной реферальной ссылки. Вы будете получать выгодные комиссионные от каждой покупки, совершенной
                по вашей ссылке. Мы предоставим вам все необходимые инструменты для эффективного продвижения программы -
                будь то на вашем сайте или в социальных сетях. Кроме того, вы сможете отслеживать статистику по вашим
                рефералам и получать оперативную техническую поддержку. Присоединяйтесь к нашей партнерской программе
                прямо сейчас!
            </p>
        </div>
        <div>
            <el-table
                :data="formData"
                :show-header="false"
                style="width: 100%"  
                class="program-description__table"
                :row-class-name="tableRowClassName">

                <el-table-column min-width="150">
                    <template slot-scope="scope">
                        <div class="higlited-title platform-wrap">
                           <span class="program-description__table-name">{{ (scope.row.name) }}</span>
                           <span v-html="scope.row.description"></span>
                        </div>
                    </template>
                </el-table-column>
                <!-- <el-table-column min-width="100">
                    <template slot-scope="scope">
                        <div class="higlited-title">
                            {{ (scope.row.involvement) }} %
                        </div>
                    </template>
                </el-table-column> -->
            </el-table>
        </div>
        <a class="program-description__link" href="https://profitads.ru/referal/" target="_blank">Узнать больше</a>
    </div>
</template>
<script>
const tableRowClassName = ({
    row
}) => {
    if (row.has_new_comment) {
        return 'higlited'
    }
    return ''
}
export default {
    name: 'ProgramDescription',
    components: {

    },
    props: {},
    data() {
        return {
            locale: process.env.VUE_APP_LOCALE,
            formData:[
                {
                    id:1, 
                    name: 'Какое вознаграждение вы получите?', 
                    status: 1, 
                    involvement: 1.5,
                    description: `Вы можете настроить ваше вознаграждение под себя: для каждой рекламной площадки можно выбрать какой процент от итоговой суммы вознаграждения останется вам, а какой будет у вашего реферала. <br /> <br />Например, от рекламной площадки можно получить 10% вознаграждения от расходов в месяц. В личном кабинете агента вы устанавливаете правило: 60% вознаграждения получаете вы, а 40% — получает ваш реферал. Допустим, реферал потратил за месяц на этой рекламной площадке 1 000 000р., тогда по итогу месяца вы получите 60 000р. , а реферал 40 000р.`
                },
            ],
            isShowPage: false,
            tableRowClassName
        }
    },
    methods: {
        getIcon(data) {
        const icons = {
            telegram: require("@/assets/icons/icon-telegram.svg"),
            vkads: require("@/assets/icons/icon-vkreklama.svg"),
            vkontakte: require("@/assets/icons/icon-vkontacte.svg"),
            yandexdirect: require("@/assets/icons/yandex-direct-icon.svg"),
            promopages: require("@/assets/icons/icon-promo-page.jpeg"),
            googleads: require("@/assets/icons/icon-google.svg"),
            mytarget: require("@/assets/icons/icon-mytarget-new.svg"),
        };
        return icons[data] || '';
    },
    },
}
</script>
<style lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.program-description {
    display: flex;
    flex-direction: column;
    width: 70%;

    &__table-name {
        font-weight: 600;
    }

    &__table {
        .cell {
            white-space: normal !important; 
        }
    }
    &__benefits {
        display: flex;
        flex-direction: column;
        gap: 1.6rem;
        color: #333;
        font-family: 'Roboto';
        font-style: normal;
        line-height: normal;
        margin-bottom: 6rem;
        h3 {
            font-size: 20px;
            font-weight: 700;
        }
        p {
            font-size: 16px;
            font-weight: 400;
        }
    }
    &__link {
        background-color: var(--primary);
        color: #fff;
        border: 1px solid var(--primary);
        padding: 1rem 2rem;
        border-radius: 4px;
        max-width: 20rem;
        width: 50%;
        margin-top: 2rem;
        text-align: center;
        font-family: 'Roboto';
        &:hover {
            cursor: pointer;
        }
    }
}
.el-table {
    .el-table__cell {
        padding-left: 1rem;
        padding-right: 1.2rem;
    }
}
.platform-wrap {
    display: flex;
    // align-items: center;
    text-align: left;
    flex-direction: column;
    gap: 1rem;
    img {
        max-width: 25px;
    }

}
@include below(769px) {
    .program-description {
        width: 100%;
    }
}

</style>