export const AdType = {
  CPM: "Оплата за 1000 показов",
  CPC: "Оплата за клик на баннер",
  CPA: "Оплата за целевое действие",
  Other: "Иное"
}

export const AdFormKeys = {
  TEXT: "Text",
  BANNER: "Banner",
  TEXT_GRAPHIC: "TextGraphic",
  VIDEO: "Video",
  AUDIO: "Audio",
  AUDIO_BROADCAST: "AudioBroadcast",
  VIDEO_BROADCAST: "VideoBroadcast",
  // OTHER: "Other"
}

export const AdForm = {
  [AdFormKeys.TEXT]: "Текстовый блок",
  [AdFormKeys.BANNER]: "Баннер",
  [AdFormKeys.TEXT_GRAPHIC]: "Текстово-графический блок",
  [AdFormKeys.VIDEO]: "Видеоролик",
  [AdFormKeys.AUDIO]: "Аудиозапись",
  [AdFormKeys.AUDIO_BROADCAST]: "Аудиотрансляция в прямом эфире",
  [AdFormKeys.VIDEO_BROADCAST]: "Видеотрансляция в прямом эфире",
  // [AdFormKeys.OTHER]: "Иное"
}



export const MediaFileTypeKey = {
  IMAGE: "IMAGE",
  VIDEO: "VIDEO",
  AUDIO: "AUDIO",
  ZIP: "ZIP",
  OTHER: "OTHER",
}

export const MediaFileTypeValue = {
  [MediaFileTypeKey.IMAGE]: 'image',
  [MediaFileTypeKey.VIDEO]: 'video',
  [MediaFileTypeKey.AUDIO]: 'audio',
  [MediaFileTypeKey.ZIP]: 'zip',
  [MediaFileTypeKey.OTHER]: 'other',
}

export const MediaFileTypeName = {
  [MediaFileTypeKey.IMAGE]: 'Изображение',
  [MediaFileTypeKey.VIDEO]: 'Видео',
  [MediaFileTypeKey.AUDIO]: 'Аудио',
  [MediaFileTypeKey.ZIP]: 'Архив',
  [MediaFileTypeKey.OTHER]: 'Иное',
}

export const AdFormToMediaFileType = {
  [AdFormKeys.BANNER]: MediaFileTypeValue[MediaFileTypeKey.IMAGE],
  [AdFormKeys.TEXT_GRAPHIC]: MediaFileTypeValue[MediaFileTypeKey.IMAGE],
  [AdFormKeys.VIDEO]: MediaFileTypeValue[MediaFileTypeKey.VIDEO],
  [AdFormKeys.AUDIO]: MediaFileTypeValue[MediaFileTypeKey.AUDIO],
  [AdFormKeys.AUDIO_BROADCAST]: MediaFileTypeValue[MediaFileTypeKey.AUDIO],
  [AdFormKeys.VIDEO_BROADCAST]: MediaFileTypeValue[MediaFileTypeKey.VIDEO],
}