import axios from 'axios'
import router from '../../router'


const apiUrl = process.env.VUE_APP_API_URL
const locale = process.env.VUE_APP_LOCALE

const state = () => ({
  status: '',
  token: localStorage.getItem('token') || localStorage.getItem('client_token') || '',
  data: false,
  user: {},
  role: null,
  errors: null,
  isVerified: false,
  resetPasswordEmail: null,
  businessAccounts: null,
  pagination: null,
  activeAccount: null,
  holdingsAccounts: null,
  holdingsPagination: null,
  registerEmail: null,
  unreadNotifications: null,
  currencyRates: null,
  showUserLegalSwitch: true,
  platformBallance: {
    mytarget: {
      balance: {
        total_balance: 0,
      }
    },
    vkontakte: {
      balance: {
        total_balance: 0.00,
      }
    },
    yandex: {
      balance: {
        total_balance: 0.00,
      }
    },
    google: {
      balance: {
        total_balance: 0.00,
      }
    },
    vkads: {
      balance: {
        total_balance: 0.00,
      }
    }
  },
})
const getters = {
  isLoggedIn: state => !!state.token,
  authStatus: state => state.status,
  unreadNotifications: state => state.unreadNotifications,
}
const mutations = {
  setUnreadNotifications(state, data) {
    state.unreadNotifications = data
  },
  setPlatformBallance(state, data) {
    state.platformBallance = data
  },
  setShowUserLegalSwitch(state, data) {
    state.showUserLegalSwitch = data
  },
  auth_request(state) {
    state.status = 'loading'
  },
  auth_success(state, token) {
    state.status = 'success'
    state.token = token
  },
  auth_error(state, err) {
    state.status = 'error'
    state.errors = err
  },
  auth_phone_error(state, err) {
    state.status = 'error'
    state.errors.phone = err
  },
  auth_email_error(state, err) {
    state.status = 'error'
    state.errors = err
  },
  logout(state) {
    state.status = ''
    state.token = ''
    state.user = {}
    state.errors = null
    state.isVerified = false
    state.resetPasswordEmail = null
    state.businessAccounts = null
    state.pagination = null
    state.activeAccount = null
  },
  setData(state, data) {
    state[data.label] = data.data
  },
}
const actions = {
  getUnreadNotifications({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      const requestParams = state.role === 'partner' ? data : {};
      axios({ 
        url: apiUrl + '/tracker/helpdesk/new-count', 
        method: 'GET', 
        params: requestParams,
        headers: { 'Authorization': `Bearer ${state.token}` } })
        .then(response => {
          commit('setUnreadNotifications', response.data.data)
          resolve(response)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  register({ commit }, user) {
    return new Promise((resolve, reject) => {
      commit('auth_request')
      axios({ url: apiUrl + '/auth/register', data: user, method: 'POST' })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          commit('auth_error', err.response.data.errors)
          reject(err)
        })
    })
  },
  registerFast({ commit }, user) {
    return new Promise((resolve, reject) => {
      commit('auth_request')
      axios({ url: apiUrl + '/auth/register/fast', data: user, method: 'POST' })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          commit('auth_error', err.response.data.errors)
          reject(err)
        })
    })
  },

  changePassword({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit('auth_request')
      axios({ url: apiUrl + '/auth/change-password', data, method: 'POST' })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          commit('auth_error', err.response.data.errors)
          reject(err)
        })
    })
  },
  login({ commit, dispatch }, user) {
    console.log('user', user)
    return new Promise((resolve, reject) => {
      commit('auth_request')
      axios({ url: apiUrl + '/auth/login', data: user, method: 'POST' })
        .then(resp => {
          const token = resp.data.data.token
          const email = user.email
          commit('auth_success', token)
          dispatch('getUser', { token, email })
          resolve(resp)
        })
        .catch(err => {
          commit('auth_error', err.response.data.errors)
          reject(err)
        })
    })
  },
  loginWithPhoneVerify({ commit }, user) {
    return new Promise((resolve, reject) => {
      commit('auth_request')
      axios({ url: apiUrl + '/auth/login', data: user, method: 'POST' })
        .then(resp => {
          const token = resp.data.data.token
          commit('auth_success', token)
          // dispatch('getUser', { token })
          resolve(resp)
        })
        .catch(err => {
          commit('auth_error', err.response.data.errors)
          reject(err)
        })
    })
  },
  getUser ({ commit, dispatch, state }, {token, email}) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + '/auth/userinfo', method: 'GET', headers: { 'Authorization': `Bearer ${token}`} })
        .then(resp => {
          commit('setData', { label: 'data', data: true })
          commit('setData', { label: 'user', data: resp.data.data })
          commit('setData', { label: 'role', data: resp.data.data.role })
          commit('setData', { label: 'isVerified', data: resp.data.data.is_verified })
          
          localStorage.setItem('verified', resp.data.data.is_verified)
          if (resp.data.data.role === 'manager' || resp.data.data.role === 'admin' || resp.data.data.role === 'finance') {
            localStorage.setItem('token', state.token)
          } else {
            localStorage.setItem('client_token', state.token)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + state.token
          }

          if (resp.data.data.is_verified) {
            if (resp.data.data.role === 'manager' || resp.data.data.role === 'finance') {
              if (router.history.current.name === 'Login' || router.history.current.name === 'MainPage' || router.history.current.name === 'VerifyEmail') {
                router.push({ path: '/clients-accounts' })
              }
            } else if (resp.data.data.role === 'admin') {
              if (router.history.current.name === 'Login' || router.history.current.name === 'MainPage' || router.history.current.name === 'VerifyEmail') {
                router.push({ path: '/clients-accounts' })
              }
            } else if (resp.data.data.role === 'user' || resp.data.data.role === '') {
              dispatch('getBusinessAccounts')
              if (router.history.current.name === 'Login' || router.history.current.name === 'MainPage' || router.history.current.name === 'VerifyEmail') {
                const isOpenReferral = localStorage.getItem('isOpenReferral')
                if(!resp.data.data.is_ba && resp.data.data.agent && Object.keys(resp.data.data.agent).length > 0) {
                  localStorage.setItem('isOpenReferral', true)
                  router.push({ name: 'ReferralStatistics' })
                } else if(isOpenReferral && resp.data.data.is_ba && resp.data.data.agent && Object.keys(resp.data.data.agent).length > 0){
                  router.push({ name: 'ReferralStatistics' })
                } else {
                  router.push({ path: '/statistics' })
                }
            }
          } else if (resp.data.data.role === 'partner') {
            dispatch('getRepresentativeBusinessesAccounts', { id: resp.data.data.id, token: token })
            if (router.history.current.name === 'Login' || router.history.current.name === 'MainPage' || router.history.current.name === 'VerifyEmail') {
              // router.push({ path: '/statistics' })
              router.push({ path: '/ad-representative' })
            }
          }
          } else {
            router.push({ path: '/verify-email', params: { email: email } })
          }
          
          resolve(resp)
        })
        .catch(err => {
          console.log(err)
          console.log(err.response)
          reject(err)
        })
    })
  },
authAsUser({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      axios({ url: `${apiUrl}/admin/get-client-token/${data.id}`, method: 'GET', headers: { 'Authorization': `Bearer ${data.token}` } })
        .then(resp => {
          commit('setData', { label: 'activeAccount', data: resp.data.data.business_account })
          localStorage.setItem('client_token', resp.data.data.client_token)
          localStorage.setItem('active_client', resp.data.data.business_account.id)
          localStorage.setItem('active_client_email', resp.data.data.business_account.user.email)
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + resp.data.data.client_token
          dispatch('getBallance', resp.data.data.business_account.id)
          dispatch('getBusinessAccounts', data.id)
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },

  getBallance({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + `/business-account/${id}/get-balance`, method: 'GET', headers: { 'Authorization': `Bearer ${state.token}` } })
        .then(response => {
          commit('setPlatformBallance', response.data.data.services)
          resolve(response)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })

  },

  logout({ commit }) {
    return new Promise((resolve) => {
      commit('logout')
      localStorage.removeItem('token')
      localStorage.removeItem('client_token')
      localStorage.removeItem('verified')
      localStorage.removeItem('active_client')
      localStorage.removeItem('activeBA')
      localStorage.removeItem('sortParams')
      localStorage.removeItem('active_client_email')
      localStorage.removeItem('isOpenReferral')
      delete axios.defaults.headers.common['Authorization']
      resolve()
    })
  },
  getNewKey({ commit, dispatch }, id) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + '/business-account/' + id + '/reset-client-secret', method: 'POST' })
        .then(resp => {
          dispatch('getBusinessAccounts', id)
          resolve(resp)
        })
        .catch(err => {
          commit('auth_error', err.response.data.errors)
          reject(err)
        })
    })
  },
  verifyEmail({ commit }, url) {
    return new Promise((resolve, reject) => {
      axios({ url, method: 'GET' })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          commit('auth_error', err.response.data.errors)
          reject(err)
        })
    })
  },
  repeatVerify({ commit }) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + '/auth/email/verify-resend', method: 'GET' })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          commit('auth_error', err.response.data.errors)
          reject(err)
        })
    })
  },
  repeatVerifyFromRegistration({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + '/auth/email/verify-resend', method: 'POST', data })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          commit('auth_error', err.response.data.errors)
          reject(err)
        })
    })
  },
  sendCode({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + '/auth/send-reset-password-code', data, method: 'POST' })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          commit('auth_error', err.response.data.errors)
          reject(err)
        })
    })
  },
  checkCode({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + '/auth/check-reset-password-code', data, method: 'POST' })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          commit('auth_error', err.response.data.errors)
          reject(err)
        })
    })
  },
  resetPassword({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + '/auth/reset-password', data, method: 'POST' })
        .then(resp => {
          const token = resp.data.data.token
          localStorage.setItem('verified', resp.data.data.is_verify)
          localStorage.setItem('token', token)
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
          commit('auth_success', token)
          dispatch('getUser', { token, email: data.email })
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          commit('auth_error', err.response.data.errors)
          reject(err)
        })
    })
  },

  sendSmsCode({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + '/auth/verify-phone', method: 'POST', data })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          // commit('auth_error', err.response.data.errors)
          commit('auth_phone_error', err.response.data.errors)
          reject(err)
        })
    })
  },
  resendSmsCode({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + '/auth/verify-phone/resend', method: 'POST', data })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          commit('auth_error', err.response.data.errors)
          reject(err)
        })
    })
  },
  checkSmsCode({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + '/auth/verify-phone/code', method: 'POST', data })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          commit('auth_error', err.response.data.errors)
          reject(err)
        })
    })
  },

  getBusinessAccounts({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + '/business-account?per_page=1000', method: 'GET' })
        .then(resp => {
          // if (resp.data.data && !resp.data.data.length && locale ==='RU') {
          //   dispatch('Dialog/setData', { label: 'activeDialog', data: 'ReducedСommissions' }, {root: true});
          //   dispatch('Dialog/setData', { label: 'dialogVisible', data: true }, {root: true});
          // }
          commit('setData', { label: 'businessAccounts', data: resp.data.data })
          const pagination = {
            total: resp.data.total,
            currentPage: resp.data.current_page
          }
          commit('setData', { label: 'pagination', data: pagination })
          const localBA = localStorage.getItem('activeBA')
          if (localBA) {
            commit('setData', { label: 'activeAccount', data: resp.data.data.find(el => el.id === localBA) })
          } else if (id) {
            commit('setData', { label: 'activeAccount', data: resp.data.data.find(el => el.id === id) })
            localStorage.setItem('activeBA', id)
          } else if (resp.data.data && resp.data.data.length) {
            commit('setData', { label: 'activeAccount', data: resp.data.data[0] })
            localStorage.setItem('activeBA', resp.data.data[0].id)
          }
          resolve(resp)
        })
        .catch(err => {
          console.log(err)
          commit('auth_error', err.response.data.errors)
          reject(err)
        })
    })
  },
  getRepresentativeBusinessesAccounts: async ({ commit, dispatch }, data) => {
    try {
      const resp = await axios({
        url: apiUrl + '/business-partners/accounts/' + data.id,
        method: 'GET',
        headers: { 'Authorization': `Bearer ${data.token}` }
      });

      const business_accounts = await Promise.all(
        resp.data.data.map(item => dispatch('getInfoBARepresentatives', { user_id: data.id, ba_id: item.id, token: data.token }))
      );

      const businessAccountsData = business_accounts.map(item => item.data.data);

      commit('setData', { label: 'businessAccounts', data: businessAccountsData });

      const pagination = {
        total: resp.data.total,
        currentPage: resp.data.current_page
      };
      commit('setData', { label: 'pagination', data: pagination });

      const localBA = localStorage.getItem('activeBA');
      if (localBA) {
        dispatch('getRepresentativeBAInfo', { user_id: data.id, ba_id: localBA, token: data.token });
      } else if (resp.data.data.length) {
        dispatch('getRepresentativeBAInfo', { user_id: data.id, ba_id: resp.data.data[0].id, token: data.token });
        localStorage.setItem('activeBA', resp.data.data[0].id);
      }

      return resp;
    } catch (err) {
      console.error(err.response);
      throw err;
    }
  },
  getRepresentativeBAInfo({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${apiUrl}/business-partners/accounts/${data.user_id}/${data.ba_id}`,
        method: 'GET',
        // headers: { 'Authorization': `Bearer ${data.token}` }
      })
        .then(businessAccount => {
          commit('setData', { label: 'activeAccount', data: businessAccount.data.data })
          resolve(businessAccount)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  getInfoBARepresentatives(_, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${apiUrl}/business-partners/accounts/${data.user_id}/${data.ba_id}`,
        method: 'GET',
        // headers: { 'Authorization': `Bearer ${data.token}` }
      })
        .then(businessAccount => {
          resolve(businessAccount)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  createBusinessAccount({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + '/business-account', data, method: 'POST' })
        .then(resp => {
          dispatch('getBusinessAccounts')
          resolve(resp)
        })
        .catch(err => {
          commit('auth_error', err.response.data.errors)
          reject(err)
        })
    })
  },
  createBusinessAccountLikeManager({ commit }, { data, token }) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + '/admin/clients', data, method: 'POST', headers: { 'Authorization': `Bearer ${token}` } })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          commit('auth_error', err.response.data.errors)
          reject(err)
        })
    })
  },
  editBusinessAccount({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + '/business-account/' + data.id, data: data.data, method: 'PATCH' })
        .then(resp => {
          dispatch('getBusinessAccount', data.id)
          resolve(resp)
        })
        .catch(err => {
          commit('auth_error', err.response.data.errors)
          reject(err)
        })
    })
  },
  getBusinessAccount({ commit, dispatch }, id) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + '/business-account/' + id, method: 'GET' })
        .then(async resp => {
          commit('setData', { label: 'activeAccount', data: resp.data.data })
          await dispatch('getBusinessAccounts', id)
          resolve(resp)
        })
        .catch(err => {
          commit('auth_error', err.response.data.errors)
          reject(err)
        })
    })
  },
  getHoldingsBusinessAccounts({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + '/hollding/business-accounts', method: 'GET', params })
        .then(resp => {
          commit('setData', { label: 'holdingsAccounts', data: resp.data.data })
          const pagination = {
            total: resp.data.total,
            currentPage: resp.data.current_page
          }
          commit('setData', { label: 'holdingsPagination', data: pagination })
          resolve(resp)
        })
        .catch(err => {
          commit('auth_error', err.response.data.errors)
          reject(err)
        })
    })
  },

  getCurrencyRates({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + '/currency-rates', method: 'GET', params })
        .then(resp => {
          commit('setData', { label: 'currencyRates', data: resp.data.data })
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  unsubscribeEmail(ctx, url) {
    return new Promise((resolve, reject) => {
      axios({ url: url, method: 'GET' })
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          console.log(err.response)
          reject(err.response.data)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
