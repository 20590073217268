import axios from 'axios'

const apiUrl = process.env.VUE_APP_API_URL

const state = () => ({
  errors: null,
  usersList: null,
  history: null,
  sum: null,
  paginationEvents: null,
  transactionTypes: null,
  pagination: {
    total: 0,
    currentPage: 1
  },
  agentsLinksList: [],
  formData: [
    { id: "uuid-1234", name: "Петров Петр Петрович", email: "petrov@mail.com" },
    { id: "uuid-5678", name: "Сидоров Сидор Сидорович", email: "sidorov@mail.com" },
    { id: "uuid-9876", name: "Козлов Козел Козлович", email: "kozlov@mail.com" },
    { id: "uuid-2468", name: "Никитин Никита Никитич", email: "nikitin@mail.com" },
    { id: "uuid-1357", name: "Алексеев Алексей Алексеевич", email: "alexeev@mail.com" },
    { id: "uuid-4321", name: "Иванов Иван Иванович", email: "mail@mail.com" }
  ]
})

const getters = {
  getFilteredUsers(state) {
    return state.usersList.map(item => ({ ...item, isHovered: false }))
  }
}

const mutations = {
  addReferralLink(state, referralLink) {
    state.agentsLinksList.push(referralLink)
  },
  setData(state, data) {
    state[data.label] = data.data
  },
}

const actions = {
  addReferral({ commit }, { data, token }) {
    return new Promise((resolve, reject) => {
      axios({
        url: apiUrl + '/agents',
        data,
        method: 'POST',
        headers: { 'Authorization': `Bearer ${token}` }
      })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          commit('setData', { label: 'errors', data: err.response.data.errors })
          reject(err)
        })
    })
  },
  editReferral({ commit }, { data, token }) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${apiUrl}/agents/${data.id}`,
        data,
        method: 'PUT',
        headers: { 'Authorization': `Bearer ${token}` }
      })
        .then(resp => {
          commit('setData', { label: 'editAgent', data: resp.data.data })
          resolve(resp)
        })
        .catch(err => {
          commit('setData', { label: 'errors', data: err.response.data.errors })
          reject(err)
        })
    })
  },
  getUsers({ state, commit }, { id, token }) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${apiUrl}/agents/${id}/users`,
        method: 'GET',
        headers: { 'Authorization': `Bearer ${token}` }
      })
        .then(resp => {
          commit('setData', { label: 'usersList', data: resp.data.data })
          resolve(resp)
        })
        .catch(err => {
          commit('setData', { label: 'errors', data: err.response.data.errors })
          reject(err)
        })
    })
  },
  getUsersByAgentId({state, commit}, {agent_id, token}){
    return new Promise((resolve, reject) => {
      axios({
        url: `${apiUrl}/agents/${agent_id}/users`,
        method: 'GET',
        headers: { 'Authorization': `Bearer ${token}` }
      })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getDetailStatistics({ state, commit }, { agent, token }) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${apiUrl}/agents/${agent}/statistics`,
        method: 'POST',
        headers: { 'Authorization': `Bearer ${token}` }
      })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  transferToTheAccount({ commit }, { data, token }) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${apiUrl}/agents-transactions/balance-to-checking-account`,
        data,
        method: 'POST',
        headers: { 'Authorization': `Bearer ${token}` }
      })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          commit('setData', { label: 'errors', data: err.response.data.errors })
          reject(err)
        })
    })
  },
  transferToTheBusinessAccount({ commit }, { data, token }) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${apiUrl}/agents-transactions/balance-to-cashback`,
        data,
        method: 'POST',
        headers: { 'Authorization': `Bearer ${token}` }
      })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          commit('setData', { label: 'errors', data: err.response.data.errors })
          reject(err)
        })
    })
  },
  getHistory({ commit }, { data, token }) {
    return new Promise((resolve, reject) => {
      axios({
        url: apiUrl + `/agents-transactions`,
        method: 'GET',
        params: data,
        headers: { 'Authorization': `Bearer ${token}` }
      })
        .then(resp => {
          commit('setData', {
            label: 'history', data: resp.data.data.map(
              (item) => ({
                ...item,
                name: item.type === 1 ? 'Начисление' : (item.type === 2 ? 'Вывод средств' : (item.type === 3 ? 'Корректировка' : '')),
                desc: item.type === 1 ? 'Начисление агентских баллов' : (item.type === 2 ? 'Вывод агентских баллов' : (item.type === 3 ? 'Корректирующая транзакция агентских баллов' : ''))
              })
            )
          })
          const paginationEvents = {
            total: resp.data.meta.total,
            currentPage: resp.data.meta.current_page
          }
          commit('setData', { label: 'paginationEvents', data: paginationEvents })
          commit('setData', { label: 'sum', data: resp.data.total })
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  getTransactionTypes({ commit }, token) {
    return new Promise((resolve, reject) => {
      axios({
        url: apiUrl + `/agents-transactions/types`,
        method: 'GET',
        headers: { 'Authorization': `Bearer ${token}` }
      })
        .then(resp => {
          commit('setData', { label: 'transactionTypes', data: resp.data.data })
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  getReferralLinkInfoByAgentAndCode({ state, commit }, { code, agent, token }) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${apiUrl}/agents/${agent}/links/${code}`,
        method: 'GET',
        headers: { 'Authorization': `Bearer ${token}` }
      })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getAgentListLinks({ state, commit }, { id, token }) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${apiUrl}/agents/${id}/links`,
        method: 'GET',
        headers: { 'Authorization': `Bearer ${token}` }
      })
        .then(resp => {
          commit('setData', { label: 'agentsLinksList', data: resp.data.data })
          resolve(resp)
        })
        .catch(err => {
          commit('setData', { label: 'errors', data: err.response.data.errors })
          reject(err)
        })
    })

  },
  postAgentLink({ state, commit }, { agent, data, token }) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${apiUrl}/agents/${agent}/links`,
        data,
        method: 'POST',
        headers: { 'Authorization': `Bearer ${token}` }
      })
        .then(resp => {
          resolve(resp)
          commit('addReferralLink', resp.data.data)
        })
        .catch(err => {
          commit('setData', { label: 'errors', data: err.response.data.errors })
          reject(err)
        })
    })
  },
  getReferralList({ state, commit }, { id, token }) {
    return new Promise((resolve, reject) => {
      // axios({
      //   url: ,
      //   method: 'GET',
      //   headers: { 'Authorization': `Bearer ${token}` }
      // })
      //   .then(resp => {
      //     commit('setData', { label: 'agentsList', data: resp.data.data })
      //     resolve(resp)
      //   })
      //   .catch(err => {
      //     commit('setData', { label: 'errors', data: err.response.data.errors })
      //     reject(err)
      //   })
    })
  },
  getServices({ state, commit }, { token }) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${apiUrl}/agents/services`,
        method: 'GET',
        headers: { 'Authorization': `Bearer ${token}` }
      })
        .then(resp => {
          commit('setData', { label: 'agentsServices', data: resp.data.data })
          resolve(resp)
        })
        .catch(err => {
          commit('setData', { label: 'errors', data: err.response.data.errors })
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};