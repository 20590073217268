<template>
  <div class="app-pages">
    <div class="reports-page page" :class="{'locale-usa': locale === 'USA'}">
      <div class="reports-page__title">
        <Title :text="$t('reports.title')" />
      </div>
      <div v-loading="loading"  class="reports-page__content">
        <div class="reports-page__filters">
          <div class="fields">
            <DatePicker
              :title="$t('data_picker.title')"
              :use-timezone="true"
              @changeDates="changeDate"
            />
            <MultiSelector
              v-if="optionsUsers && optionsUsers.length"
              :title="$t('reports.clients')" 
              :options="optionsUsers"
              :selected="'all'"
              :placeholder="$t('reports.choose_clients')"
              @handleChange="changeUsers"
            />
            <MultiSelector
              :title="$t('reports.platforms')" 
              :options="platforms"
              :selected="selectedPlatforms"
              @handleChange="changePlatform"
            />
          </div>
          <div class="buttons">
            <ButtonElement
              :text="$t('button_props.reference')"
              view="empty"
              @click-button="getReferenceValues"
            />
            <ButtonElement
              :text="$t('button_props.upload_to_xlsx')"
              :disabled="!this.selectedUsers.length"
              @click-button="exportExel"
            />
          </div>
        </div>
        <div class="reports-page__content-box">
          <div class="reports-page__content-box-title">{{$t("reports.graph")}}</div>
          <div class="reports-page__graph-section">
            <div class="main-graph">
              <div class="main-graph__platforms">
               {{ $t("reports.display_graph") }}:
                <label v-for="platform in selectedPlatforms" :key="platform.id" class="input__item-checkbox">
                  <input
                    type="checkbox"
                    :value="platform.value"
                    v-model="displayedPlatforms"
                  >
                  <span class="input__item-checkbox__check">
                    <img src="@/assets/icons/icon-done.svg">
                  </span>
                  <span class="input__item-checkbox__info">
                    <span class="input__item-checkbox__title">
                      {{ platform.name }}
                    </span>
                  </span>
                </label>
              </div>
              <div class="main-graph__settings">
                <div class="main-graph__settings-box first">
                  <label class="settings-choice-item">
                    <input type="radio" v-model="graphType" value="margin">
                    <span>
                      {{$t('reports.profit')}}
                    </span>
                  </label>
                  <label class="settings-choice-item">
                    <input type="radio" v-model="graphType" value="spents">
                    <span>
                      {{$t('reports.otkrytka')}}
                    </span>
                  </label>
                </div>

                <div class="main-graph__settings-box">
                  <label class="settings-choice-item">
                    <input type="radio" v-model="graphType2" value="accumulator">
                    <span>
                      {{$t('reports.accumulate')}}
                    </span>
                  </label>
                  <label class="settings-choice-item">
                    <input type="radio" v-model="graphType2" value="dynamics">
                    <span>
                      {{$t('reports.dynamics')}}
                    </span>
                  </label>
                </div>
              </div>
              <LineChartElement
                v-loading="loadingGraph"
                ref="lineChart"
                :data="lineDataChart"
                :key="Date.now()"
                :show-legend="false"
                tiks-collor="#BFC2C6"
                :tiks-size="14"
                :show-grid-lines="false"
              />
            </div>
            <div class="graph-info">
              <div class="graph-info__box">
                <div class="graph-info__box-title">{{$t('reports.platforms')}}</div>
                <div class="graph-info__platforms">
                  <div class="graph-info__platforms-item vk"><img src="@/assets/icons/icon-vk-graph.svg" class="graph-icon"> vk.com</div>
                  <div class="graph-info__platforms-item yandex"><img src="@/assets/icons/icon-yandex-graph.svg" class="graph-icon"> yandex</div>
                  <!-- <div class="graph-info__platforms-item facebook"><img src="@/assets/icons/icon-facebook-graph.svg" class="graph-icon"> facebook</div> -->
                  <div class="graph-info__platforms-item mytarget"><img src="@/assets/icons/icon-mytarget-graph.svg" class="graph-icon"> myTarget</div>

                  <div class="graph-info__platforms-item mytarget"><img src="@/assets/icons/icon-vk-graph.svg" class="graph-icon"> vkads</div>
                  <div class="graph-info__platforms-item mytarget"><img src="@/assets/icons/icon-yandex-graph.svg" class="graph-icon"> google</div>
                  <div class="graph-info__platforms-item telegram"><img src="@/assets/icons/icon-telegram-graph.svg" class="graph-icon"> telegram</div>
                </div>
              </div>
              <div v-if="selectedUsers.length < 7" class="graph-info__box">
                <div class="graph-info__box-title">{{$t('reports.clients')}}</div>
                <div class="graph-info__clients">
                  <div v-for="(user, index) in selectedUsers" :key="user.id" class="graph-info__clients-item">
                    <div class="client-collor" :style="`background: ${colors[index]}`"></div>
                    {{ user.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="reports-page__content-box">
          <div class="reports-page__content-box-title">{{$t('reports.pivot_table')}}</div>
          <div class="main-graph__platforms">
            {{$t('reports.display_columns')}}:
            <label class="input__item-checkbox">
              <input
                type="checkbox"
                :value="$t('reports.cashback')"
                v-model="tableCols.cashback"
              >
              <span class="input__item-checkbox__check">
                <img src="@/assets/icons/icon-done.svg">
              </span>
              <span class="input__item-checkbox__info">
                <span class="input__item-checkbox__title">
                 {{ $t('reports.cashback') }}
                </span>
              </span>
            </label>
            <label class="input__item-checkbox">
              <input
                type="checkbox"
                :value="$t('reports.profit_rate')"
                v-model="tableCols.marginRate"
              >
              <span class="input__item-checkbox__check">
                <img src="@/assets/icons/icon-done.svg">
              </span>
              <span class="input__item-checkbox__info">
                <span class="input__item-checkbox__title">
                  {{ $t('reports.profit_rate') }}
                </span>
              </span>
            </label>
            <label class="input__item-checkbox">
              <input
                type="checkbox"
                :value="$t('reports.otkrytka')"
                v-model="tableCols.spent"
              >
              <span class="input__item-checkbox__check">
                <img src="@/assets/icons/icon-done.svg">
              </span>
              <span class="input__item-checkbox__info">
                <span class="input__item-checkbox__title">
                  {{ $t('reports.otkrytka') }}
                </span>
              </span>
            </label>
            <label class="input__item-checkbox">
              <input
                type="checkbox"
                :value="$t('reports.profit')"
                v-model="tableCols.margin"
              >
              <span class="input__item-checkbox__check">
                <img src="@/assets/icons/icon-done.svg">
              </span>
              <span class="input__item-checkbox__info">
                <span class="input__item-checkbox__title">
                  {{ $t('reports.profit') }}
                </span>
              </span>
            </label>
            <label class="input__item-checkbox">
              <input
                type="checkbox"
                :value="$t('reports.profit')"
                v-model="tableCols.averagePercentage"
              >
              <span class="input__item-checkbox__check">
                <img src="@/assets/icons/icon-done.svg">
              </span>
              <span class="input__item-checkbox__info">
                <span class="input__item-checkbox__title">
                  {{$t('reports.middle')}}
                </span>
              </span>
            </label>
          </div>

          <!-- <div v-if="tableStatistic && tableStatistic.length && selectedUsers && selectedUsers.length && tableClients" class="statistic-table">
            <div class="statistic-table__main-col">
              <div class="statistic-table__main-row statistic-table__main-row--border-top">
                <div class="statistic-table__main-col-container" style="width: 250px">
                  <div class="statistic-table__col statistic-table__col-placeholder"></div>
                  <div class="statistic-table__col statistic-table__col-placeholder">{{$t('reports.client')}}</div>
                </div>
                <div class="statistic-table__main-col-container" style="width: 95px">
                  <div class="statistic-table__col statistic-table__col-placeholder"></div>
                  <div class="statistic-table__col statistic-table__col-placeholder">{{$t('reports.platform')}}</div>
                </div>
              </div>
              <div v-for="client in clientsSort(tableClients[Object.keys(tableClients)[0]])" :key="client.business_account_id" class="statistic-table__main-row">
                <div class="statistic-table__main-col-container" style="width: 250px">
                  <div class="statistic-table__col statistic-table__col--strong" :title="client.business_account_name">{{ cutUserName(client.business_account_name) }}</div>
                  <div v-for="item in client.services.length - 1" :key="item.service" class="statistic-table__col"></div>
                  <div class="statistic-table__col statistic-table__col--strong statistic-table__col--blue">{{$t('reports.total')}}</div>
                </div>
                <div class="statistic-table__main-col-container" style="width: 95px">
                  <div v-for="item in servicesSort(client.services)" :key="item.service" class="statistic-table__col">{{ item.service }}</div>
                  <div class="statistic-table__col statistic-table__col--blue"></div>
                </div>
              </div>
              <div v-if="tableStatistic && tableStatistic.length" class="statistic-table__main-row">
                <div class="statistic-table__main-col-container" style="width: 250px">
                  <div class="statistic-table__col statistic-table__col--strong">{{$t('reports.grand_total')}}</div>
                  <div v-for="item in tableStatistic[0].total_services.length" :key="item" class="statistic-table__col"></div>
                </div>
                <div class="statistic-table__main-col-container" style="width: 95px">
                  <div class="statistic-table__col"></div>
                  <div v-for="item in clientsSort(tableStatistic[0].total_services)" :key="item.service" class="statistic-table__col">{{ item.service }}</div>
                </div>
              </div>
            </div>
            <div v-for="item in tableStatistic" :key="item.month" class="statistic-table__main-col">
              <div class="statistic-table__main-row statistic-table__main-row--border-top">
                <div class="statistic-table__col statistic-table__col-placeholder statistic-table__col--blue statistic-table__col--center">{{ montName(item.month) }}</div>
              </div>

              <div class="statistic-table__main-row">
                <div v-show="tableCols.cashback" class="statistic-table__main-col-container" style="width: 95px">
                  <div class="statistic-table__col statistic-table__col-placeholder">{{$t('reports.cashback')}}</div>
                </div>
                <div v-show="tableCols.marginRate" class="statistic-table__main-col-container" style="width: 250px">
                  <div class="statistic-table__col statistic-table__col-placeholder">{{$t('reports.profit_rate')}}</div>
                </div>
                <div v-show="tableCols.spent" class="statistic-table__main-col-container" style="width: 150px">
                  <div class="statistic-table__col statistic-table__col-placeholder">{{$t('reports.otkrytka')}}</div>
                </div>
                <div v-show="tableCols.margin" class="statistic-table__main-col-container" style="width: 150px">
                  <div class="statistic-table__col statistic-table__col-placeholder">{{$t('reports.profit')}}</div>
                </div>
                <div v-show="tableCols.averagePercentage" class="statistic-table__main-col-container" style="width: 95px">
                  <div class="statistic-table__col statistic-table__col-placeholder">{{$t('reports.middle')}}</div>
                </div>
              </div>
              <div v-for="client in clientsSort(tableClients[item.month])" :key="client.business_account_id">
                <div v-for="service in servicesSort(client.services)" :key="service.service" class="statistic-table__main-row">
                  <div v-show="tableCols.cashback" class="statistic-table__main-col-container" style="width: 95px">
                    <div class="statistic-table__col">{{ service.cashback.toFixed(2) }}</div>
                  </div>
                  <div v-show="tableCols.marginRate" class="statistic-table__main-col-container" style="width: 250px">
                    <div class="statistic-table__col">{{ service.marginRate.toFixed(2) }}</div>
                  </div>
                  <div v-show="tableCols.spent" class="statistic-table__main-col-container" style="width: 150px">
                    <div class="statistic-table__col">{{ numberWithSpacesCeil((+service.spent).toFixed()) }}</div>
                  </div>
                  <div v-show="tableCols.margin" class="statistic-table__main-col-container" style="width: 150px">
                    <div class="statistic-table__col">{{ numberWithSpacesCeil((+service.margin).toFixed())  }}</div>
                  </div>
                  <div v-show="tableCols.averagePercentage" class="statistic-table__main-col-container" style="width: 95px">
                    <div class="statistic-table__col"></div>
                  </div>
                </div>

                <div class="statistic-table__main-row">
                  <div v-show="tableCols.cashback" class="statistic-table__main-col-container" style="width: 95px">
                    <div class="statistic-table__col statistic-table__col--blue statistic-table__col--strong"></div>
                  </div>
                  <div v-show="tableCols.marginRate" class="statistic-table__main-col-container" style="width: 250px">
                    <div class="statistic-table__col statistic-table__col--blue statistic-table__col--strong"></div>
                  </div>
                  <div v-show="tableCols.spent" class="statistic-table__main-col-container" style="width: 150px">
                    <div class="statistic-table__col statistic-table__col--blue statistic-table__col--strong">
                      {{ numberWithSpacesCeil((+client.total.spent).toFixed()) }}
                    </div>
                  </div>
                  <div v-show="tableCols.margin" class="statistic-table__main-col-container" style="width: 150px">
                    <div class="statistic-table__col statistic-table__col--blue statistic-table__col--strong">
                      {{ numberWithSpacesCeil((+client.total.margin).toFixed()) }}
                    </div>
                  </div>
                  <div v-show="tableCols.averagePercentage" class="statistic-table__main-col-container" style="width: 95px">
                    <div class="statistic-table__col statistic-table__col--blue statistic-table__col--strong">
                      {{ (+client.total.averagePercentage).toFixed(2) }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="statistic-table__main-row">
                <div v-show="tableCols.cashback" class="statistic-table__main-col-container" style="width: 95px">
                  <div class="statistic-table__col"></div>
                </div>
                <div v-show="tableCols.marginRate" class="statistic-table__main-col-container" style="width: 250px">
                  <div class="statistic-table__col"></div>
                </div>
                <div v-show="tableCols.spent" class="statistic-table__main-col-container" style="width: 150px">
                  <div class="statistic-table__col statistic-table__col--strong">
                    {{ numberWithSpacesCeil((+item.totals.spent).toFixed()) }}
                  </div>
                </div>
                <div v-show="tableCols.margin" class="statistic-table__main-col-container" style="width: 150px">
                  <div class="statistic-table__col statistic-table__col--strong">
                    {{ numberWithSpacesCeil((+item.totals.margin).toFixed()) }}
                  </div>
                </div>
                <div v-show="tableCols.averagePercentage" class="statistic-table__main-col-container" style="width: 95px">
                  <div class="statistic-table__col statistic-table__col--strong">{{ (+item.totals.averagePercentage).toFixed(2) }}</div>
                </div>
              </div>

              <div 
                v-for="totalPlatform in item.total_services" 
                :key="totalPlatform.service"
                class="statistic-table__main-row"
              >
                <div v-show="tableCols.cashback" class="statistic-table__main-col-container" style="width: 95px">
                  <div class="statistic-table__col"></div>
                </div>
                <div v-show="tableCols.marginRate" class="statistic-table__main-col-container" style="width: 250px">
                  <div class="statistic-table__col"></div>
                </div>
                <div v-show="tableCols.spent" class="statistic-table__main-col-container" style="width: 150px">
                  <div class="statistic-table__col">{{ numberWithSpacesCeil((+totalPlatform.spent).toFixed()) }}</div>
                </div>
                <div v-show="tableCols.margin" class="statistic-table__main-col-container" style="width: 150px">
                  <div class="statistic-table__col">{{ numberWithSpacesCeil((+totalPlatform.margin).toFixed()) }}</div>
                </div>
                <div v-show="tableCols.averagePercentage" class="statistic-table__main-col-container" style="width: 95px">
                  <div class="statistic-table__col">{{ (+totalPlatform.averagePercentage).toFixed(2) }}</div>
                </div>
              </div>
            </div>
          </div> -->
          
          <el-table 
            v-if="formatTable(tableStatistic).length"
            class="reports__table" 
            :data="formatTable(tableStatistic)"
            :row-class-name="tableRowClassName" 
            :header-cell-class-name="tableHeaderRowClassName"
            style="width: 100%"
            :empty-text="locale === 'RU' ? 'Нет данных' : 'No data'"
          >
            <el-table-column fixed width="345">
              <el-table-column 
                :label="$t('reports.client')" 
                width="250" 
              >
                <template slot-scope="scope">
                  <div class="reports__table-row-cell bold"> 
                    {{ scope.row.client }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column 
                :label="$t('reports.platform')" 
                width="95"
              >
                <template slot-scope="scope">
                  <div class="reports__table-row-cell" > 
                    {{ scope.row.service }}
                  </div>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column 
              v-for="(item, index) in tableStatistic" 
              :label="montName(item.month)"
              :key="item.month"
            >
              <el-table-column 
                v-if="tableCols.cashback"
                :label="$t('reports.cashback')" 
                width="170"
              >
                <template slot-scope="scope">
                  <div class="reports__table-row-cell"> 
                    
                    {{ scope.row.totals[index] && scope.row.totals[index].cashback.toFixed(2) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column 
                v-if="tableCols.marginRate"
                :label="$t('reports.profit_rate')" 
                width="170"
              >
                <template slot-scope="scope">
                  <div class="reports__table-row-cell" > 
                    {{ scope.row.totals[index] && scope.row.totals[index].marginRate.toFixed(2) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column 
                v-if="tableCols.spent"
                :label="$t('reports.otkrytka')" 
                width="150"
              >
                <template slot-scope="scope">
                  <div class="reports__table-row-cell" > 
                    {{ scope.row.totals[index] && numberWithSpacesCeil((scope.row.totals[index].spent).toFixed())}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column 
                v-if="tableCols.margin"
                :label="$t('reports.profit')" 
                width="145"
              >
                <template slot-scope="scope">
                  <div class="reports__table-row-cell" > 
                    {{ scope.row.totals[index] && numberWithSpacesCeil((scope.row.totals[index].margin).toFixed())}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column 
                v-if="tableCols.averagePercentage"
                :label="$t('reports.middle')" 
                width="110"
              >
                <template slot-scope="scope">
                  <div class="reports__table-row-cell">
                    {{ scope.row.totals[index] && scope.row.totals[index].averagePercentage.toFixed(2) }}
                  </div>
                </template>
              </el-table-column>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Title from "@/components/elements/Title";
import ButtonElement from "@/components/elements/ButtonElement";
import MultiSelector from "@/components/sort/MultiSelector";
import DatePicker from "@/components/sort/DatePicker";
import numberWithSpacesCeil from '@/mixin/numberWithSpaces'
import fixDateFormatNumbers from '@/mixin/fixDate'
import { getRandomInteger } from "@/utils/helpers";

export default {
  name: 'Reports',
  components: {
    Title,
    ButtonElement,
    DatePicker,
    MultiSelector,
    LineChartElement: () => import('@/components/elements/ReportsLineChartElement'),
  },
  mixins: [numberWithSpacesCeil, fixDateFormatNumbers],
  data () {
    return {
      locale: process.env.VUE_APP_LOCALE,
      loading: true,
      loadingGraph: false,
      token: null,
      selectedUsers: [],
      platforms: [
        { id: 1, name: 'vk.com', value: 'vkontakte' },
        { id: 2, name: 'yandex', value: 'yandex' },
        { id: 3, name: 'myTarget', value: 'mytarget' },
        { id: 4, name: 'vkads', value: 'vkads' },
        { id: 5, name: 'google', value: 'google' },
        { id: 6, name: 'Telegram', value: 'telegram' },
        /* { id: 4, name: 'facebook', value: 'facebook'}, */
      ],
      selectedPlatforms: [
        { id: 1, name: 'vk.com', value: 'vkontakte' },
        { id: 2, name: 'yandex', value: 'yandex' },
        { id: 3, name: 'myTarget', value: 'mytarget' },
        { id: 4, name: 'vkads', value: 'vkads' },
        { id: 5, name: 'google', value: 'google' },
        { id: 6, name: 'Telegram', value: 'telegram' },
      ],
      displayedPlatforms: ['vkontakte','yandex','mytarget', 'vkads', 'google','telegram'],
      graphType: 'margin',
      graphType2: 'dynamics',
      colors: [],
      tableCols: {
        cashback: true,
        marginRate: true,
        spent: true,
        margin: true,
        averagePercentage: true,
      },
      lineOptions: {
        vkontakte:  [2, 2],
        yandex:     [5, 5],
        facebook:   [2, 2, 5, 2],
        mytarget:   [0, 0],
        vkads:      [2, 2],
        google:     [5, 5],
        telegram:   [0, 0],
      },
      servicesColors: {
        vkontakte:  '#FF974C',
        yandex:     '#B73339',
        facebook:   'blue',
        mytarget:   '#27AE60',
        vkads:   'gray',
        google:   'green',
        telegram: "#229ED9"
      },
      monthNames: ['январь', 'февраль', 'март', 'апрель', 'май', 'июнь', 'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь'],
      monthNamesUSA: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      dataChart: {
        datasets: [],
        labels: []
      },
      tableClients: null,
      selectedDate: null,
    }
  },
  computed: {
    optionsUsers() {
      const optionsUsers = []
      if (this.$store.state.Users.usersList && this.$store.state.Users.usersList.length) {
        const users = this.$store.state.Users.usersList
        return users 
        // users.forEach(user => {
        //   if (user.is_active) {
        //     optionsUsers.push({
        //       id: user.id,
        //       name: this.cutUserName(!user.is_legal ? `${user.first_name} ${user.last_name}` : user.legal_name),
        //     })
        //   }
        // });
      }
      return optionsUsers
    },
    graphicStatistic() {
      return this.$store.state.Reports.graphicStatistic
    },
    tableStatistic() {
      return this.$store.state.Reports.tableStatistic
    },
    selectedPlatformsList() {
      const platforms = []
      this.selectedPlatforms.forEach(el => {
        platforms.push(el.value)
      })
      return platforms
    },
    lineDataChart: {
      get() {
        return this.dataChart
      },
      set(value) {
        this.dataChart = value
      }
    }
  },
  watch: {
    graphicStatistic(val) {
      if (val && val.length) {
        this.buildGraphData(val)
      } else {
        this.loadingGraph = false
        // this.dataChart = {}
        this.lineDataChart = null
      }
    },
    tableStatistic(val) {
      if (val && val.length) {
        this.buildTableData(val)
      } else {
        this.tableClients = null
      }
    },
    graphType() {
      if (this.graphicStatistic && this.graphicStatistic.length) this.buildGraphData(this.graphicStatistic)
    },
    graphType2() {
      if (this.graphicStatistic && this.graphicStatistic.length) this.buildGraphData(this.graphicStatistic)
    },
    displayedPlatforms() {
      if (this.graphicStatistic && this.graphicStatistic.length) {
        this.buildGraphData(this.graphicStatistic)
      }
      if (this.tableStatistic && this.tableStatistic.length) {
        this.buildTableData(this.tableStatistic)
      }
    },
    optionsUsers(val) {
      this.loading = false
      if (val.length) {
        this.generateRandomColors(val.length)
      }
    },
  },
  created() {
    this.token = localStorage.getItem('token')
    this.$store.commit('Users/setData', { label: 'usersList', data: null })
    this.$store.dispatch('Users/getUsersList', {params: {per_page: 1000, is_short: 1}, token: this.token})
  },
  methods: {
    tableHeaderRowClassName(item) {
      if (item.columnIndex !== 0 && item.rowIndex === 0) {
        return 'header-cell text-center'
      } 
      return 'header-cell'
    },
    tableRowClassName({ row }) {
      if (row.client === 'Итого') {
        return 'highlight-gray'
      }
      return ''
    },
    updateResultMap(resultMap, id, client, service, totals, tableStatisticsIndex) {
      if (tableStatisticsIndex === 0) {
        if (!resultMap.has(id)) {
          resultMap.set(id, {
            custom_id: id,
            client,
            service,
            totals: [totals]
          });
        }
      } else {
        if (resultMap.has(id)) {
          resultMap.get(id).totals.push(totals);
        }
      }
    },
    formatTable(tableStatistics) {
      const resultMap = new Map();

      if (!tableStatistics || !tableStatistics.length) return [];

      tableStatistics.forEach(({ clients, month, total_services, totals }, tableStatisticsIndex) => {
        if (!clients.data.length) return;

        this.clientsSort(clients.data).forEach((client, clientIndex) => {

          this.servicesSort(client.services).forEach((service, index) => {
            const customId = getRandomInteger();
            const clientName = index === 0 ? this.cutUserName(client.business_account_name) : '';
            const serviceTotals = {
              month,
              cashback: service.cashback || 0,
              margin: service.margin,
              marginRate: service.marginRate,
              spent: service.spent,
              averagePercentage: service.marginRate - service.cashback
            };
            
            this.updateResultMap(resultMap, customId, clientName, service.service, serviceTotals, tableStatisticsIndex);
          });

          const totalClients = {
            month,
            cashback: 0,
            margin: client.total.margin,
            marginRate: 0,
            spent: client.total.spent,
            averagePercentage: client.total.averagePercentage || 0
          };

          this.updateResultMap(resultMap, `total-clients-${clientIndex}`, 'Итого', '', totalClients, tableStatisticsIndex);
        });

        const totalService = {
          month,
          cashback: 0,
          margin: totals.margin,
          marginRate: 0,
          spent: totals.spent,
          averagePercentage: totals.averagePercentage || 0
        };

        this.updateResultMap(resultMap, 'total-service', 'Общий итог', '', totalService, tableStatisticsIndex);

        this.clientsSort(total_services).forEach((totalService, index) => {
          const serviceTotals = {
            month,
            cashback: 0,
            margin: totalService.margin,
            marginRate: 0,
            spent: totalService.spent,
            averagePercentage: totalService.averagePercentage || 0
          };

          this.updateResultMap(resultMap, index, '', totalService.service, serviceTotals, tableStatisticsIndex);
        });
      });
      console.log('Res', Array.from(resultMap.values()).filter((item) => item.client.includes("ПЛАНЕТА ТВОРЧЕСТВА")))
      return Array.from(resultMap.values());
    },
    getReferenceValues() {
      this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'ReferenceValues' })
      this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
    },
    getNewData() {
      const users = []
      this.loadingGraph = true
      this.lineDataChart = null
      // this.dataChart = {}
      if (this.selectedUsers && this.selectedUsers.length) {
        this.selectedUsers.forEach(el => {
          users.push(el.id)
        })
      }

      if (this.selectedDate) {
        const params = {
          start_date : this.fixDateFormatNumbers(this.selectedDate.startDate),
          end_date  : this.fixDateFormatNumbers(this.selectedDate.endDate),
          services : this.selectedPlatformsList,
        }
        if (users.length && users.length !== this.optionsUsers.length) params.business_account = users
        this.$store.dispatch('Reports/getGraphicStatistic', {params, token: this.token})
        this.$store.dispatch('Reports/getTableStatistic', {params, token: this.token})
      }
    },
    exportExel() {
      this.loading = true
      const users = []
      if (this.selectedUsers && this.selectedUsers.length) {
        this.selectedUsers.forEach(el => {
          users.push(el.id)
        })
        const params = {
          start_date : this.selectedDate.startDate,
          end_date  : this.selectedDate.endDate,
          services : this.selectedPlatformsList,
          business_account : users,
        }
        this.$store.dispatch('Reports/getExelStatistic', {params, token: this.token})
          .then((data) => {
            this.loading = false
            let link = document.createElement('a');
            link.href = `${data.data.link}`;
            link.setAttribute('download', 'download');
            link.click();
            link.remove();
          })
          .catch(() => {
            this.loading = false
          })
      }
    },
    changeDate(val) {
      if (val) {
        this.selectedDate = val
        if (this.selectedUsers.length) this.getNewData()
      }
    },
    changeUsers(values) {
      this.selectedUsers = values
      this.getNewData()
    },
    changePlatform(values) {
      this.selectedPlatforms = values
      this.getNewData()
    },
    generateRandomColors(count) {
      for (let i = 0; i < count; i++) {
        const x = Math.floor(Math.random() * 256);
        const y = Math.floor(Math.random() * 256);
        const z = Math.floor(Math.random() * 256);
        const bgColor = "rgb(" + x + "," + y + "," + z + ")";
        this.colors.push(bgColor)
      }
    },
    getFormattedDate(date, isOnlyDate) {
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      };
      if (!isOnlyDate) {
        options.hour = '2-digit';
        options.minute = '2-digit';
      }
      return new Date(date).toLocaleDateString('ru-Ru', options);
    },
    montName(date) {
      const dateSplit = date.split('.')
      if(this.locale==='USA') return `${this.monthNamesUSA[+dateSplit[0] - 1]} ${dateSplit[1]}`
      return `${this.monthNames[+dateSplit[0] - 1]} ${dateSplit[1]}`
    },
    buildGraphData(val) {
      const labels = []
      let datasets = []
      const graphData = {}
      let sumResult = {
        vkontakte: 0,
        yandex: 0,
        facebook: 0,
        mytarget: 0,
        vkads: 0,
        google: 0,
      }
      if (this.selectedUsers.length < 7) {
        val.forEach(el => {
          labels.push(this.getFormattedDate(el.day, true));

          el.services.forEach(service => {
            if (this.displayedPlatforms.includes(service.service)) {
              const filledServiceClients = {}
              this.selectedUsers.forEach((bussinessAccount) => {
                if (!Array.isArray(service.clients) && service.clients[bussinessAccount.id]) {
                  filledServiceClients[bussinessAccount.id] = service.clients[bussinessAccount.id]
                } else {
                  filledServiceClients[bussinessAccount.id] = { spents: 0, margin: 0 }
                }
              })
            
              Object.keys(filledServiceClients).forEach((businessAccountId) => {
                const valueByKey = filledServiceClients[businessAccountId]

                if (!graphData[businessAccountId]) {
                  graphData[businessAccountId] = {}
                }

                if (!graphData[businessAccountId][service.service]) {
                  graphData[businessAccountId][service.service] = [];
                }

                const value = valueByKey[this.graphType]
                if (this.graphType2 === 'accumulator') {
                  sumResult[service.service] += +value;
                  graphData[businessAccountId][service.service].push((sumResult[service.service]).toFixed(2));
                } else {
                  graphData[businessAccountId][service.service].push(value.toFixed(2));
                }
              })
            }
          });
        });

        for (const businessAccountId in graphData) {
          const userIndex = this.selectedUsers.findIndex(el => el.id === businessAccountId);
          const userColor = this.colors[userIndex];

          // Initialize a map to track datasets by their data string representation for each user
          const userDatasetMap = new Map();
          for (const platformKey in graphData[businessAccountId]) {
            const data = graphData[businessAccountId][platformKey];
            const dataString = JSON.stringify(data);
            if (userDatasetMap.has(dataString)) {
              // If a dataset with the same data already exists, append the platformKey to its label
              const existingDataset = userDatasetMap.get(dataString);
              existingDataset.label += `, ${platformKey}`;
            } else {
              // Otherwise, create a new dataset entry for the user
              const newDataset = {
                label: `${this.selectedUsers[userIndex].name} - ${platformKey}`,
                data: data,
                borderColor: userColor,
                borderWidth: 2,
                backgroundColor: 'transparent',
                tension: 0.2,
                borderDash: this.lineOptions[platformKey],
                radius: 0,
              };
              userDatasetMap.set(dataString, newDataset);
            }
          }

          // Convert the map values back to an array for the user's datasets
          datasets.push(...Array.from(userDatasetMap.values()));
        }
      } else {
        val.forEach(el => {
          labels.push(this.getFormattedDate(el.day, true))
          el.services.forEach((service) => {
            if (this.displayedPlatforms.includes(service.service)) {
              if (!graphData[service.service]) graphData[service.service] = []
              if (this.graphType2 === 'accumulator') {
                sumResult[service.service] += +service.total[this.graphType]
                graphData[service.service].push((sumResult[service.service]).toFixed(2))
              } else {
                graphData[service.service].push((service.total[this.graphType]).toFixed(2))
              }
            }
          })
        })


        for (const key in graphData) {
          datasets.push({
            label: key,
            data: graphData[key],
            borderColor: this.servicesColors[key],
            borderWidth: 2,
            backgroundColor: 'transparent',
            tension: 0.2,
            borderDash: this.lineOptions[key],
            radius: 0,
          })
        }
      }

      this.lineDataChart = {
        labels,
        datasets
      }

      // this.dataChart = {
      //   labels,
      //   datasets
      // }
      this.loadingGraph = false
    },
    buildTableData(val) {
      const clients = {}
      val.forEach(el => {
        if (!clients[el.month]) clients[el.month] = {}
        if (el.clients && el.clients.data) {
          el.clients.data.forEach(client => {
            if (!clients[el.month][client.business_account_id]) {
              clients[el.month][client.business_account_id] = client
            }  
          })
        }
      })
      this.tableClients = clients
    },
    servicesSort(services) {
      if (services) {
        const x = JSON.parse(JSON.stringify(services))
        const sort = x.sort(function (a, b) {
          if (a.service > b.service) {
            return 1;
          }
          if (a.service < b.service) {
            return -1;
          }
          return 0;
        });
        return sort
      }
    },
    clientsSort(clients) {
      if (clients) {
        const x = JSON.parse(JSON.stringify(clients))
        const arr = []
        for (const key in x) {
          arr.push(x[key])
        }
        const sort = arr.sort(function (a, b) {
          if (a.business_account_name > b.business_account_name) {
            return 1;
          }
          if (a.business_account_name < b.business_account_name) {
            return -1;
          }
          return 0;
        });
        return sort
      }
    },
    cutUserName(name) {
      const regex = /("[^"]+")/g;
      const matches = name.match(regex);
      const result = matches ? matches.join(" ") : "";
      return (matches && matches.length) ? result.replace(/Индивидуальный предприниматель /g, "") : name.replace(/Индивидуальный предприниматель /g, "")
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";
.reports {
  &__table .el-table__cell {
    padding-top: 6px;
    padding-bottom: 6px;
  }
  &__table .el-table__cell > .cell {
    padding: 0 !important;
  }

  &__table .el-table__cell:not(.header-cell) > .cell {
    white-space: normal;
    word-break: break-word; 
  }
  &__table .el-table__row.highlight-gray > .el-table__cell {
    background: $background2;
  }

  &__table .el-table__cell.header-cell {
    font-size: 1.3rem;
    color: #7F858D;
    background: $basicWhite !important;
  }

  &__table .el-table__cell.text-center {
    text-align: center;
    
    background: $background2 !important;
  }

  &__table-row-cell {
    font-size: 1.3rem;
    &.bold {
      font-weight: 600;
    }
  }
}

.reports-page {
  &.locale-usa {
    .reports-page__title {
      font-family: 'Gilroy';
      font-style: normal;
      font-weight: 700;
      font-size: 3.2rem;
      line-height: 4rem;
    }
  }
  &__title {
    margin-bottom: 2.5rem;
  }

  &__filters {
    display: flex;
    justify-content: space-between;
    
    .fields {
      display: flex;

      .selector, .date-picker {
        margin-right: 3.5rem;
        margin-bottom: 3rem;
      }
    }

    .buttons {
      display: flex;

      .button {
        display: block;
        margin-left: 3.5rem;
        margin-bottom: 3rem;
        padding: 0 2.4rem;
        height: 5rem !important;
      }
    }
  }

  &__content-box {
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(192, 192, 192, 0.12);
    border-radius: 6px;
    padding: 3rem;
    margin-bottom: 3rem;

    &-title {
      margin-bottom: 2rem;
      font-weight: 700;
      font-size: 2.4rem;
    }
  }

  &__graph-section {
    display: flex;
  }

  .main-graph {
    width: 100%;
    padding-right: 7.4rem;

    &__platforms {
      display: flex;
      align-items: center;
      color: $gray;
      margin-bottom: 3.2rem;

      .input__item-checkbox {
        margin-left: 2.3rem;
        color: $basicBlack;
        cursor: pointer;
      }

      .input__item-checkbox__title {
        font-size: 1.4rem;
      }
    }

    &__settings {
      display: flex;
      margin-bottom: 3.2rem;

      &-box {
        &.first {
          margin-right: 10rem;
        }
      }

      .settings-choice-item {
        margin-right: 1.5rem;
        //styles for custom radiobutton
        >input[type="radio"] { //for an input element with type="radio"
          position: absolute;
          z-index: -1;
          opacity: 0;
        }
        //for the label element associated with .settings-choice-item
        >span {       
          display: inline-flex;
          align-items: center;
          user-select: none;
          //creating a before pseudo-element in the label with the following styles
          &::before {
            content: '';
            display: inline-block;
            width: 1em;
            height: 1em;
            flex-shrink: 0;
            flex-grow: 0;
            border: 1px solid #adb5bd;
            border-radius: 50%;
            margin-right: 0.5em;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 50% 50%;
          }
        }
        //styling when hovering over the radio
        >input[type="radio"]:not(:disabled):not(:checked)+span:hover::before { 
          border-color: var(--primaryLight);
        }
        //styles for active radio button (when clicked)
        >input[type="radio"]:not(:disabled):active+span::before { 
          background-color: var(--primary);
          border-color: var(--primary);
          padding: 5px;
        }
        //styles for a radio button that is in the checked state
        >input[type="radio"]:checked+span::before {
          border-color: var(--primary);
          background-color: var(--primary);
        }
        //styles for a radio button that is in the disabled state
        >input[type="radio"]:disabled+span::before {
          background-color: #e9ecef;
        }
      }
    }
  }

  .graph-info {
    width: 30rem;
    font-size: 1.4rem;
    line-height: 1.6rem;
    flex-shrink: 0;

    &__box {
      width: 100%;
      margin-bottom: 3.6rem;

      &-title {
        font-weight: 700;
        margin-bottom: 1.6rem;
      }
    }

    &__platforms {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: -1.5rem;

      &-item {
        display: flex;
        align-items: center;
        width: calc(100% / 2);
        margin-bottom: 1.5rem;

        .graph-icon {
          margin-right: 1rem;
          flex-shrink: 0;
        }
      }
    }

    &__clients {
      margin-bottom: -1.5rem;

      &-item {
        display: flex;
        align-items: flex-start;
        margin-bottom: 1.5rem;

        .client-collor {
          width: 1rem;
          height: 1rem;
          border-radius: 50%;
          margin-right: .6rem;
          flex-shrink: 0;
          position: relative;
          top: .2rem;
        }
      }
    }
  }

  .statistic-table {
    width: 100%;
    overflow: auto;
    display: flex;

    &__main-col {
      border-right: 1px solid $gray5;
      width: max-content;
    }

    &__main-row {
      display: flex;

      &--border-top {
        border-top: 1px solid $gray5;
      }
    }

    &__row {
      border-bottom: 1px solid $gray5;
      display: flex;
    }

    &__col {
      min-height: 4.3rem;
      border-bottom: 1px solid $gray5;
      padding: 0 0.5rem;
      display: flex;
      align-items: center;
      width: 100%;
      white-space: nowrap;
      overflow: auto;

      font-size: 1.3rem;

      scrollbar-width: thin;
      scrollbar-color: #395FE4 #eee;
      -ms-overflow-style: -ms-autohiding-scrollbar;

      &::-webkit-scrollbar {
        width: 6px;
        height: 2px;
        background: #eee;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background-color: var(--primary);
      }

      &-placeholder {
        color: $gray;
        font-weight: 400;
        font-size: 1.4rem;
        min-height: 3.6rem;
      }

      &--strong {
        font-weight: 600;
      }

      &--blue {
        background: $background2;
      }

      &--center {
        justify-content: center;
      }
    }
  }
  .selector {
    width: 100%;
    max-width: 20rem;

    @include below($sm) {
      max-width: 100%;
    }

    &__options {
      position: absolute;
      top: calc(100% + 1rem);
      left: 0;
      width: calc(100% + 1.7rem);
      background: $backgroundInput;
      border: 1px solid $border;
      border-radius: 2px;
      z-index: 1;
      overflow: auto;
      max-height: 30rem;

      &-item {
        cursor: pointer;
        transition: 0.3s ease-out;
        padding: 0.7rem 1.5rem;
        margin: .3rem 0;

        &:hover {
          background: var(--primary);
          color: $basicWhite;
        }

        &--active {
          background: var(--primary);
          color: $basicWhite;
        }
      }
    }

    @include below(769px) {
      &__content {
        width: 100%;
      }
    }
  }
}

</style>
