import axios from 'axios'

const apiUrl = process.env.VUE_APP_API_URL

const state = () => ({
  usersList: null,
  pagination: null,
  usersAllList: null,
  editUser: null,
  errors: null,
})
const getters = {}
const mutations = {
  setData (state, data) {
    state[data.label] = data.data
  },
  setUser(state, data) {
    state.usersAllList = state.usersAllList.map(el => {
      if(el.id === data.id) {
        el.role = data.user.role
        el.email = data.user.email
      }
      return el
    })
  },
  addUser(state, data) {
    data.created_at = new Date()
    state.usersAllList.push(data)
  },
}
const actions = {
  getUsersListByUserId ({ commit }, data) {
    return axios({
      url: `${apiUrl}/admin/clients`,
      method: 'GET',
      params: data.params,
      headers: { 'Authorization': `Bearer ${data.token}` }
    })
  },
  getUsersList ({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${apiUrl}/admin/clients`,
        method: 'GET',
        params: data.params,
        headers: { 'Authorization': `Bearer ${data.token}` }
      })
        .then(resp => {
          commit('setData', { label: 'usersList', data: resp.data.data })
          const pagination = {
            total: resp.data.total,
            currentPage: resp.data.current_page
          }
          commit('setData', { label: 'pagination', data: pagination })
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  getAllUsersList ({ commit }, data) {

    return new Promise((resolve, reject) => {
      axios({
        url: `${apiUrl}/admin/users`,
        method: 'GET',
        params: data.params,
        headers: { 'Authorization': `Bearer ${data.token}` }
      })
        .then(resp => {
          console.log('resp getAllUsersList', resp)
          commit('setData', { label: 'usersAllList', data: resp.data.data })
          const pagination = {
            total: resp.data.meta.total,
            currentPage: resp.data.meta.current_page
          }
          commit('setData', { label: 'pagination', data: pagination })
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  getUser({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: apiUrl + '/admin/users/' + data.id,
        data: data.user,
        method: 'GET',
        headers: { 'Authorization': `Bearer ${data.token}` }
      })
        .then(resp => {
          resolve(resp.data.data)
        })
        .catch(err => {
          commit('setData', { label: 'errors', data: err.response.data.errors })
          reject(err)
        })
    })
  },
  editUser({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: apiUrl + '/admin/users/' + data.id,
        data: data.user,
        method: 'PUT',
        headers: { 'Authorization': `Bearer ${data.token}` }
      })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          commit('setData', { label: 'errors', data: err.response.data.errors })
          reject(err)
        })
    })
  },
  createUser({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: apiUrl + '/admin/users',
        data: data.user,
        method: 'POST' ,
        headers: { 'Authorization': `Bearer ${data.token}` }
      })
        .then(resp => {
          commit('addUser', resp.data.data)
          resolve(resp)
        })
        .catch(err => {
          commit('setData', { label: 'errors', data: err.response.data.errors })
          reject(err)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
