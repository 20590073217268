<template>
  <div class="selector multi-selector">
    <div class="selector__content" @click="showOptions = !showOptions">
      <div class="selector__title">{{ title }}</div>
      <div class="selector__value"> 
        <span v-if="!selectedValues.length">{{ placeholder }}</span>
        <div v-else class="selector__value-selected">
          <div v-for="user in selectedValues" :key="user.id">
            <b v-html="user.value"></b>
            <br v-if="user.value && user.name">
            <span>{{ user.name }}</span>
          </div>
        </div>
      </div>
      <div class="selector__arrow" :class="{'selector__arrow--open' : showOptions}">
        <img src="@/assets/icons/icon-arrow.svg" alt="arrow">
      </div>
    </div>
    <div v-if="showOptions" class="selector__options vk-selector">
      <div
          class="form-input selector__options-search"
          :class="
        [
          {'form-input--full' : search}
        ]"
      >
        <input v-model="search" type="text" required>
        <span class="form-input__label">
          {{ $t('search_props.placeholder') }}
        </span>
      </div>
      <div v-if="!search" class="selector__options-item">
        <div v-if="selectedValues && selectedValues.length" @click="choseAll(false)">Убрать</div>
      </div>
      <div
          v-for="option in filtredOptions"
          :key="option.id"
          class="selector__options-item"
          :class="{'selector__options-item--active': checkStatus(option.id)}"
          @click="chooseOption(option.id, option.name, option.value ? option.value : option.description)"
      >
        <div>
          <b v-if="option.description" v-html="option.description"></b>
          <br v-if="option.description && option.name">
          <span>{{ option.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SingleSelector",
  props: {
    title: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: null
    },
    selected: {
      type: [String, Array],
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    isOpen:{
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectedValues: [],
      showOptions: false,
      search: '',
    }
  },
  computed: {
    filtredOptions() {
      if (this.search && this.options) {
        return this.options.filter(el => {
          const nameMatches = el.name && el.name.toLowerCase().includes(this.search.toLowerCase());
          const descriptionMatches = el.description && el.description.toLowerCase().includes(this.search.toLowerCase());
          return nameMatches || descriptionMatches;
        });
      } else if (this.options) {
        return this.options;
      }
      return null;
    }
  },
  methods: {
    checkStatus(id) {
      if(this.selectedValues) {
        const checked = this.selectedValues.findIndex(el => el.id === id)
        return checked === -1 ? false : true
      }
    },
    chooseOption(id, name, value) {
      if (id) {
          if (!this.selectedValues) { 
          this.selectedValues = [{ id , name, value }] 
          } else if(this.selectedValues.length === 0) {
          const index = this.selectedValues.findIndex(el => el.id === id)
          if (index === -1) {
            this.selectedValues.push({ id , name, value })
          } else {
            this.removeOption(id)
          }
          } else if(this.selectedValues.length > 0) {
              for (let obj of this.selectedValues) {
                if (obj.id !== id) {
                  this.removeOption(id)
                  this.selectedValues = [{ id , name, value }] 
              }
            }
          }
          else {
              this.removeOption(id)
          }
          this.$emit('handleChange', this.selectedValues)
          this.showOptions = false
      }
    },
    removeOption(id) {
      const index = this.selectedValues.findIndex(el => el.id === id)
      if (index !== -1) {
        this.selectedValues.splice(index, 1)
      }
    },
    choseAll(add) {
      this.$emit('handleDel', this.selectedValues)
      this.selectedValues = []
      if (add) {
        this.options.forEach(el => {
          this.selectedValues.push(el)
        })
      } 
      this.$emit('handleChange', this.selectedValues)
    },
  },
}
</script>

<style scoped lang="scss">
  @import "../../assets/styles/helpers/variables";
  @import "../../assets/styles/helpers/mixin";

  .selector {
    &__content {
      padding-right: 3rem;
      min-height: 5rem;
      height: auto;
    }

    &__value {
      overflow: hidden;

      &-selected {
        display: flex;

        span {
          white-space: nowrap;
        }
      }
    } 
  }
</style>
